"use strict";

let autocomplete;

const initAutocomplete = (scope) => {
  const input = scope.querySelector(".autocomplete");
  autocomplete = new google.maps.places.Autocomplete(input, {
    types: ["street_number", "route", "street_address", "locality", "administrative_area_level_3"],
    componentRestrictions: { country: "au" },
    fields: ["address_component"],
  });

  autocomplete.addListener("place_changed", () => fillInAddress(scope));
};

const searchLocationsNear = async (center, scope) => {
  try {
    const response = await fetch(`/wp-content/themes/smile-theme-child/inc/search-locations.php?lat=${center.lat()}&lng=${center.lng()}`);
    if (!response.ok) throw new Error("Network response was not ok");
    const locationData = await response.json();

    updateHealthEngineIframe(locationData.healthEngineID);
    showResult(`Your closest Tooth Fairy practice is <a href="${locationData.link}">${locationData.location}</a>`, scope);
    showButtons(scope, locationData);
  } catch (error) {
    console.error("Error fetching location data:", error);
    showResult("An error occurred while searching for locations. Please try again.", scope);
  }
};

const updateHealthEngineIframe = (healthEngineID) => {
  const iframeContainer = document.querySelector("#he-webplugin-popup-1234");
  if (!iframeContainer) return;

  const targetIframe = iframeContainer.querySelector("iframe");
  if (!targetIframe) return;

  targetIframe.src = `https://healthengine.com.au/webplugin/?id=${healthEngineID}&source=webplugin&_ga=2.237139072.1471497193.1587336925-1048464626.1587336925&trigger=button`;
};

const showButtons = (scope, locationData) => {
  const refButtonContainer = scope.querySelector(".location-buttons");
  if (!refButtonContainer) return;

  const contactButton = refButtonContainer.querySelector(".contact");
  if (contactButton) contactButton.href = locationData.link;

  refButtonContainer.style.display = window.innerWidth < 767 ? "block" : "flex";
};

const hideButtons = () => {
  document.querySelectorAll(".location-buttons").forEach((button) => (button.style.display = "none"));
};

const resizeIframeContainer = () => {
  const iframeContainer = document.querySelector("#he-webplugin-popup-1234");
  if (iframeContainer) {
    iframeContainer.style.height = iframeContainer.style.height === "99%" ? "100%" : "99%";
  }
};

const fillInAddress = (scope) => {
  const address = scope.querySelector(".autocomplete").value;

  if (!address.includes("VIC")) {
    hideButtons();
    showResult("Your address is outside Victoria", scope);
    return;
  }

  const geocoder = new google.maps.Geocoder();
  geocoder.geocode({ address }, (results, status) => {
    if (status === google.maps.GeocoderStatus.OK) {
      searchLocationsNear(results[0].geometry.location, scope);
    } else {
      hideButtons();
      showResult("Your address is not found", scope);
    }
  });
};

const geolocate = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      const geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      const circle = new google.maps.Circle({ center: geolocation, radius: position.coords.accuracy });
      autocomplete.setBounds(circle.getBounds());
    });
  }
};

const showResult = (value, scope) => {
  scope.querySelector(".location-closest").innerHTML = value;
};

jQuery(document).ready(($) => {
  $(".search-switch").click(function (e) {
    initAutocomplete($(this).parent()[0]);

    if (!$(this).hasClass("open")) {
      $(this).addClass("open").removeClass("has-corners");
    }

    const searchUI = $(this).next(".search-ui");
    const uiHidden = searchUI.is(":hidden");

    searchUI.slideToggle({
      duration: 200,
      easing: "linear",
      start: function () {
        if (!uiHidden) {
          $(this).prev(".search-switch").removeClass("open");
        }
      },
      done: function () {
        $(this).prev(".search-switch").addClass("has-corners");
      },
    });
  });
});

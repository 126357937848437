jQuery(document).ready(function ($) {
  $(".tab-switch").click(function (e) {
    // hide all the switchable sections which aren't this one
    $("#" + $(this).data("switch") + "").addClass("show");

    // remove all the selected classes then add back the required one
    $(".link-elem").removeClass("selected");

    $(this).closest(".link-elem").addClass("selected");

    $(".switchable")
      .not("#" + $(this).data("switch") + "")
      .removeClass("show");

    if ($(window).width() < 767) {
      $(".switchable-mobile")
        .not("#" + $(this).data("switch") + "")
        .removeClass("show");
    }

    // handle the showing and hiding of any linked items
    var switchLinker = $(this).data("switchlink");

    switchLinking(switchLinker);
  });

  function switchLinking(switchLinker) {
    matchHeight(); // call the match height function when switching because it can't be run on the elements until they are visible
    $(".switchlinked").each(function (index) {
      if ($(this).hasClass(switchLinker)) {
        $(this).attr("style", "display: block");
      } else {
        $(this).attr("style", "display: none");
      }
    });
  }
});
